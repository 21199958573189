import { mapState } from 'vuex'
import Common from '@/mixins/common.mixin'

export default {
  computed: {
    ...mapState('auth', ['profile'])
  },

  methods: {
    async redirectToSurveyWallPage () {
      if (!this.profile || !Object.keys(this.profile).includes('id')) {
        this.openPageNative('Login')
        return
      }

      const GMO_PATH = 'lp/surveywall'
      if (Common.methods.checkWebview()) {
        const url = process.env.VUE_APP_USER_DOMAIN + GMO_PATH
        Common.methods.triggerEventApp(
          'OPEN_WEBVIEW',
          { url: url }
        )
      } else {
        this.$router.push({ path: GMO_PATH })
      }
    }
  }
}
